<template>
  <!-- 引用页 -->
  <div class="Reference">
    <!-- 内容 -->
    <div class="Reference_content">
      <!-- 导航 -->
      <div class="close-answer">
        <span class="list"></span>
        <span>请说明创建团队回答(必填)：</span>
        <!-- 引用按妞 -->
        <span class="icon" @click="close()"></span>
      </div>
      <div class="content">
        <textarea
          maxlength="1000"
          v-model="Models"
          id="search-icons"
          class="search-icon"
          placeholder="输入你的说明："
          @change="change"
        >
        </textarea>
        <div class="right">
          <el-button class="gray" @click="close()" style="padding:12px"
            >取消</el-button
          >
          <el-button class="blue" @click="determine()" style="padding:12px"
            >确定</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
export default {
  data() {
    return {
      Models: "",
      centers: "",
    };
  },
  props: {},
  created() {},
  components: {},
  mounted() {},
  methods: {
    async close() {
      this.$emit("closeeddd");
      ///清空输入框
      this.Models = "";
    },
    async www(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centers = response.data.message.filterContent;
          console.log("赋值成功：" + this.centers);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    async change() {
      await this.www(this.Models); ///敏感词过滤
      this.Models = this.centers; //赋值
    },
    async determine() {
      this.$emit("determine", this.Models);
      this.Models = ""; ///清空
    },
  },
};
</script>
<style scoped>
.Reference {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.Reference_content {
  width: 40%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin: auto;
  border-radius: 20px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  top: 25%;
  left: 0%;
  height: 50%;
}
.icon {
  width: 20px;
  height: 20px;
  text-align: right;
  /* margin-top: 200px; */
  position: relative;
  /* top: 20px;
       left: 30px; */
  background-image: url("~@/assets/icon/close1.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  float: right;
  cursor: pointer;
}
.close-answer {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(78, 78, 78, 1);
  position: relative;
  /* right: 10px;
    top: -10px; */
  /* cursor: pointer; */
  width: 100%;
  height: 40px;
  line-height: 40px;
  /* border: 1px solid red; */
  margin-top: 10px;
}

textarea {
  resize: none;
  font-size: 13px;
  width: 100%;
  display: inline-block;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #c7c7c7;
}
textarea:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
}
.content {
  /* border: 1px solid red; */
  width: 96%;
  height: 60%;
}
.blue {
  background: #0084ff;
  color: seashell;
  margin-left: 20px;
  width: 60px;
}
.gray {
  width: 60px;
}
.right {
  width: 100%;
  height: 20%;
  margin-top: 10px;
  text-align: right;
  display: inline-block;
  /* border: 1px solid red; */
}
</style>
