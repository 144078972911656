<template>
  <!-- 引用页 -->
  <div
    class="Reference"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 内容 -->
    <div class="Reference_content">
      <!-- 导航 -->
      <div class="close-answer">
        <span class="list"></span>
        <span>所在团队</span>
        <!-- 引用按妞 -->
        <span class="icon" @click="close()"></span>
      </div>
      <div>
        <div class="Reference_list">
          <!-- 循环内容 -->
          <div class="contents" v-for="(item, index) in Multiple" :key="index">
            <!-- 团队头像 -->
            <span class="personal_left">
              <span class="index">{{ index + 1 }}</span>
              <img class="personal_img" :src="item.img" />
            </span>
            <span class="personal_right">
              <span>
                <!-- 团队名称 -->
                <span class="name">团队名称：{{ item.name }}</span>
                <!-- 团长 -->
                <span>
                  团<span style="width:40px; display: inline-block;"></span>长：
                  {{ item.leader }}</span
                >
              </span>
              <span>
                <!-- 类型 -->
                <span>团队类型：{{ item.type }}</span>
                <!-- 创建时间 -->
                <span>
                  创建时间：
                  {{ item.creatime }}</span
                >
              </span>
            </span>
            <div class="see">
              <span style="cursor: pointer;" @click="seed(item.id)"
                >查看团队成员</span
              >
            </div>
            <!-- 显示团队成员 -->
            <div v-if="item.id == pid" class="member">
              <div class="member_checkbox">
                <input type="checkbox" @click="selectAll" v-model="checked" />
                <!-- <input type="checkbox" @click="selectAll"   v-model="checked" checked> -->
                <span id="spl" class="spl">全选</span>
                <span class="Onekey" @click="Onekey(item.id)">一键邀请</span>
              </div>
              <div v-for="(city, index) in citys" :key="index" class="details">
                <input
                  type="checkbox"
                  v-on:change="Select(city.id, city.name, index)"
                  :value="index"
                  v-model="city.checked"
                />
                <!-- <input type="checkbox"  v-if="IDDD==city.id" :value="city.id" v-model="city.checked"  disabled> -->
                <!-- 编号 -->
                <span class="indexs">{{ index + 1 }}</span>
                <!-- 头像 -->
                <span class="details_contents">
                  <img class="pid_img" :src="city.img" />
                </span>
                <!-- 姓名 -->
                <span class="details_content">
                  {{ city.name }}
                </span>
                <span class="details_content"
                  >回答数：{{ city.wdAnswerNum }}</span
                >
                <span class="details_content"
                  >采纳数：{{ city.wdAcceptNum }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Pop">
      <V-Pop @closeeddd="closeeddd" @determine="determine"></V-Pop>
    </div>
    <div v-if="Loading">
      <V-Loading></V-Loading>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import {
  dataFormat,
  formatDate,
  integralAddTwo,
  showTimeFormat,
  AcceptNum,
  AcceptNumTeam,
} from "@/js/common/index.js";
import Pop from "@/views/wisdom-answer/index/team-response/Pop/index.vue";
import { Alert } from "element-ui";
// const cityOptions = [];
export default {
  data() {
    return {
      citys: [],
      checkBox: [],
      checked: "", //选中的用户数组
      pid: -1, ///是否显示团队成员
      IDDD: 0,
      TeamID: null, ////存储选中团队ID
      teamMember: [], ///存储邀请团队成员信息
      commentContentz: "",
      loading: false, //是否显示正在加载中
      Loading: false,
      SelectAll: false, ///是否显示全选
      Pop: false, //弹框是否隐藏
    };
  },
  props: {
    Multiple: {
      type: Array,
      required: true,
    },
    manyBidding: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.IDDD = this.$store.state.userinfo.id;
  },
  mounted() {},
  components: {
    "V-Pop": Pop,
    "V-Loading": Loading, //正在加载中
  },
  methods: {
    //  关闭弹框
    closeeddd() {
      this.Pop = false;
    },
    ///确定按钮
    async determine(name) {
      ///判断输入框是否为空
      if (name == "") {
        this.$message({
          type: "warning",
          message: "输入框不能为空！",
        });
      } else {
        this.loading = true; ///显示正在加载中
        //  创建外部数据
        let infos = await QUERYED(
          "POST",
          "",
          "  WDNewQuestion(where: {id: {_eq:" +
            this.$route.query.id +
            "}}) {questionTitle   questionContent }"
        );
        axios
          .post(
            this.api.LoginURL.concat() + "/common/createQuestion",
            {
              quesId: this.$route.query.id,
              quesName: infos.data.WDNewQuestion[0].questionTitle,
              quesInf: infos.data.WDNewQuestion[0].questionContent,
              teamId: this.TeamID,
              teamMember: this.teamMember,
            },
            {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("Authorization"),
              },
            }
          )
          .then((response) => {
            console.log("邀请：",JSON.stringify(response.data));
            let ArraysQu = response.data.data.external_ques_id; //问题ID
            let ArraysTe = response.data.data.external_team_id; //团队ID
            this.call(name, ArraysQu, ArraysTe);
          })
          .catch(function(error) {
            console.log("错误" + error);
            this.shibai();
          });
        //alert(ArraysQu)
        //   axios({
        //     method: "post",
        //     url: this.api.LoginURL.concat()+'/common/createQuestion',
        //     params: {
        //       quesId: this.$route.query.id,
        //       quesName:infos.data.WDNewQuestion[0].questionTitle,
        //       quesInf:infos.data.WDNewQuestion[0].questionContent,
        //       teamId:this.TeamID,
        //       teamMember:this.teamMember
        //     },
        //     headers: {
        //         "Content-Type": "application/json",
        //         'token': token
        //     }
        //   })
        //     .then((response) => {
        //            alert("成功"+JSON.stringify(response))
        //     })
        //     .catch(function(error) {
        //       console.log("错误"+error);
        //     alert('加载失败,请重新刷新！');
        //     });
      }
    },
     shibai() {
      //alert("加载失败,请重新刷新！");
      this.$message({
        message: '加载失败,请重新刷新！',
        type: 'warning'
      });
      this.loading = false;
    },
    ///////创建问题发消息
    async call(name, ArraysQu, ArraysTe) {
      let news = [];
      let modesl = [];
      let charge = 0; //收费状态
      ///查询问题是否是收费
      let datas = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " +
          this.$route.query.id +
          "}}) {competeMode createTime}"
      );
      if (datas.data.WDNewQuestion[0].competeMode == 1) {
        ////如果是免费
        charge = 0; ///免费
      } else {
        ///如果是收费
        ////判断该问题是否被采纳，如果被采纳那么收费状态为免费（是否被采纳）
        let infodata = await QUERYED(
          "post",
          "",
          "  WDAnswerRef_aggregate(where: {question: {_eq: " +
            this.$route.query.id +
            "}, adopt: {_eq: 1}}){ aggregate {   count   }  }"
        );
        if (infodata.data.WDAnswerRef_aggregate.aggregate.count > 0) {
          ///如果被采纳
          charge = 0; //免费
        } else {
          // 判断有没有超过截至时间
          if (
            showTimeFormat(new Date()) <= datas.data.WDNewQuestion[0].createTime
          ) {
            //如果在时间内
            charge = 1; //收费
          } else {
            charge = 0; //免费
          }
        }
      }
      // 给回答表添加一条数据
      let dataas = await INSERT(
        "post",
        "",
        " insert_WdAnswerRef(objects: {question: " +
          this.$route.query.id +
          ", charge:" +
          charge +
          ",user: " +
          this.$store.state.userinfo.id +
          ',  createtimes: "' +
          new Date() +
          '", answer: "' +
          name +
          '",person :1,external_problemid :' +
          ArraysQu +
          ",external_teamid :" +
          ArraysTe +
          ",team:" +
          this.TeamID +
          "}) {  affected_rows returning {      id    }  }"
      );
      ///竞价表添加数据
      let info = await INSERT(
        "post",
        "",
        'insert_WdCompetePrice(objects: {answerContent: "' +
          name +
          '", answerDatetime: "' +
          new Date() +
          '", answerFiles: "", answerStatus: 0,question: ' +
          dataas.data.insert_WdAnswerRef.returning[0].id +
          ', isSuccess: 0, isInvied: 0, competeTime: "' +
          new Date() +
          '", competeType: 0, competeExpert: 0, competePay: ' +
          this.manyBidding +
          "}) {    affected_rows    returning {      id    }  }"
      );
      //  添加成功
      if (dataas.data.insert_WdAnswerRef.affected_rows == 1) {
        console.log(
          "说明添加成功！" + dataas.data.insert_WdAnswerRef.returning[0].id
        );
        /////////////////////////////给选中的每个成员发送消息
        for (let j = 0; j < this.checkBox.length; j++) {
          ////邀请表添加数据
          news = await INSERT(
            "POST",
            "",
            "insert_WdInvitation(objects: {user : " +
              this.$store.state.userinfo.id +
              ", userid : " +
              this.checkBox[j] +
              ', createtime : "' +
              new Date() +
              '", questioner : ' +
              this.$route.query.id +
              ", classification : 1, team : " +
              this.TeamID +
              "}) {affected_rows  returning {     id  } }"
          );
          //  //消息表发送一条消息
          modesl = await INSERT(
            "post",
            "",
            "insert_MessageNotification(objects: {type: 1, invitationid: " +
              news.data.insert_WdInvitation.returning[0].id +
              ', creatime: "' +
              new Date() +
              '",state:1, userid: ' +
              this.checkBox[j] +
              "}) {affected_rows  }"
          );
        }

        this.$message({
          type: "success",
          message: "邀请成功！",
        });
        this.Pop = false; //关闭窗口
        this.loading = false; ///关闭正在加载中
        //   console.log("发送邀请消息成功！");
      }
    },
    ///选中单选框
    async Select(userId, userName, index) {
      if (this.citys[index].checked == true) {
        ///如果选中了给选中数组添加数据
        this.checkBox.push(userId); ///添加
        this.teamMember.push({ userId: userId, userName: userName }); //添加
        console.log("选中的用户：" + JSON.stringify(this.teamMember));
        ///如果不是全选取消全选框
        // if(this.teamMember)
        if (this.checkBox.length == this.citys.length) {
          //如果全选
          this.checked = true;
        } else {
          this.checked = false;
        }
      } else if (this.citys[index].checked == false) {
        //如果取消选中就删除数据
        this.checkBox.splice(this.checkBox.indexOf(userId), 1); //删除
        this.teamMember.splice(
          this.teamMember.indexOf({ userId: userId, userName: userName }),
          1
        ); //删除
        console.log("选中的用户：" + JSON.stringify(this.teamMember));
        if (this.checkBox.length == this.citys.length) {
          //如果全选
          this.checked = true;
        } else {
          ///如果不全选
          this.checked = false;
        }
      }
    },
    //  全选
    selectAll() {
      this.checked = !this.checked;
      let span = document.getElementById("spl");
      if (this.checked) {
        this.checkBox = []; //清空
        this.teamMember = []; //清空
        this.citys.forEach((item) => {
          this.checkBox.push(item.id);
          this.teamMember.push({ userId: item.id, userName: item.name });
          item.checked = this.checked;
          span.innerHTML = "全不选";
        });
      } else {
        this.checkBox = []; //清空
        this.teamMember = []; //清空
        this.citys.forEach((item) => {
          item.checked = this.checked;
          span.innerHTML = "全选";
        });
      }
      console.log("选中的用户：" + JSON.stringify(this.teamMember));
    },
    away(id) {
      this.pid = 0;
    },
    //  关闭团队邀请
    async close() {
      this.$emit("teamclose");
    },
    onCommentInputChange() {
      this.commentContent = document.getElementById("commentContent").value;
    },
    ////邀请回答问题
    async Onekey(id) {
      let news = null;
      let modesl = null;
      this.TeamID = []; //清空

      if (this.$store.state.userinfo.id != undefined) {
        ////判断有无邀请过
        let info = await QUERYED(
          "POST",
          "",
          "  WDTeamInvitationMessageNotification(where: {beuserid: {_eq:" +
            this.$store.state.userinfo.id +
            "},wdquestionid:{_eq:" +
            this.$route.query.id +
            "},wdteamid:{_eq:" +
            id +
            "}}) {userid wdteamid questionTitle teamName  name  wdquestionid  id invitationid  creatime  }"
        );
        if (info.data.WDTeamInvitationMessageNotification.length <= 0) {
          //判断是否选中
          if (this.checkBox.length == 0) {
            //无选中
            this.$message({
              type: "warning",
              message: "您还未选择该团队的任何成员！",
            });
          } else {
            ///////显示弹框
            this.Pop = true;
            this.TeamID = id; //赋值
            //alert(JSON.stringify( this.teamMember))
            //////////////////////////////创建问题////传递给外部
          }
        } else {
          this.$message({
            type: "warning",
            message: "您已经邀请过该团队，不能重复邀请！",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "您还未登录，请先登录！",
        });
      }
    },
    ///初始化显示
    async init(id) {
      this.citys = []; //清空
      this.checkBox = []; //清空
      this.teamMember = []; //清空
      ///通过团队ID查找团队成员
      let CY = await QUERYED(
        "POST",
        "",
        "WDTeamMember(where: {team: {_eq: " +
          id +
          "}}) {name img userid wdAcceptNum  wdAnswerNum}"
      );
      for (let j = 0; j < CY.data.WDTeamMember.length; j++) {
        this.citys.push({
          id: CY.data.WDTeamMember[j].userid,
          name: CY.data.WDTeamMember[j].name,
          img: this.api.LoginURL.concat(CY.data.WDTeamMember[j].img),
          wdAcceptNum: CY.data.WDTeamMember[j].wdAcceptNum,
          wdAnswerNum: CY.data.WDTeamMember[j].wdAnswerNum,
        });
        this.checked = false;
        //   this.checkBox.push(CY.data.WDTeamMember[j].userid)
        //alert(input.checked)
      }
    },
    ///查看团队成员
    async seed(id) {
      if (this.pid == id) {
        //如果打开了
        this.pid = 0;
      } else {
        ///如果没打开
        this.pid = id; //打开
        this.init(id); //查看团队成员详情
      }
    },
  },
};
</script>
<style scoped>
@import url(~@/styles/page/index.css);
.Reference {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.Reference_content {
  width: 75%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin: 50px auto;
  margin-top: 20px;
  /* margin-bottom: 20px; */
  border-radius: 3%;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  height: 600px;
  /* overflow-y:hidden; */
  /* overflow: hidden; */
  /* border: 1px solid deepskyblue; */
  /* display: inline-block; */
}
.open {
  /* font-size: 13px; */
  cursor: pointer;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #0084ff;
}
.down {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down2.png");
  background-repeat: no-repeat;
  position: relative;
  top: 10px;
  left: 2px;
}
.up {
  /* Up.png */
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/Up.png");
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  left: 2px;
}
.icon {
  width: 20px;
  height: 20px;
  text-align: right;
  /* margin-top: 200px; */
  position: relative;
  /* top: 20px;
       left: 30px; */
  background-image: url("~@/assets/icon/close1.png");
  background-repeat: no-repeat;
  /* background-color: rebeccapurple; */
  /* background-position: auto; */
  /* display: inline-block; */
  background-size: 20px 20px;
  /* border: 1px solid red; */
  float: right;
}
.close-answer {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(78, 78, 78, 1);
  position: relative;
  /* right: 10px;
    top: -10px; */
  cursor: pointer;
  width: 100%;
  height: 40px;
  line-height: 40px;
  /* border: 1px solid red; */
  margin-top: 10px;
}
.list {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/liebiao.png");
  position: relative;
  top: 7px;
}

.Reference_list {
  width: 97%;
  height: 500px;
  /* border: 1px solid forestgreen; */
  overflow: auto;
  display: inline-block;
  /* margin-bottom: 20px; */
  position: relative;
}
.block {
  width: 100%;
  height: 30px;
  /* border: 1px solid red; */
  /* vertical-align: bottom; */
}
.personal_left {
  width: 20%;
  display: inline-block;
  /* border: 1px solid springgreen; */
  text-align: center;
}
.personal_right {
  width: 80%;
  display: inline-block;
  /* border: 1px solid forestgreen;  */
  vertical-align: top;
}
.personal_img {
  width: 70px;
  height: 70px;
  /* border: 1px solid red; */
  text-align: center;
  border-radius: 50%;
}
.personal_right > span {
  width: 100%;
  display: inline-block;
  /* border: 1px solid darkorange; */
  height: 35px;
  line-height: 35px;
}
.personal_right > span > span {
  width: 40%;
  /* border: 1px solid forestgreen; */
  display: inline-block;
  font-family: Microsoft YaHei;
  /* font-weight:bold; */
  color: rgba(4, 4, 4, 1);
  font-size: 14px;
}
/* .personal_right>span>span>span{
    border: 1px solid red;
    display: inline-block;
    width: 25%;
    text-align: right;
} */
.name {
  font-weight: bold;
  color: rgba(4, 4, 4, 1);
  font-size: 16px;
}
.contents {
  border-top: 1px dashed #dcdcdc;
  padding: 20px;
}
.index {
  width: 30%;
  height: 70px;
  line-height: 70px;
  vertical-align: top;
  /* border: 1px solid red; */
  display: inline-block;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #000000;
  font-style: italic;
  font-size: 15px;
  /* text-align: left; */
}
.see {
  width: 100%;
  height: 30px;
  line-height: 30px;
  /* border: 1px solid red; */
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0084ff;
  font-size: 13px;
  padding-right: 20px;
  text-align: right;

  /* margin-right: 30px; */
}
.city {
  width: 100%;
  display: inline-block;
  /* border:1px solid red; */
}
input {
  width: 20px;
  height: 20px;
}
.pid_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
}
.member {
  width: 80%;
  /* display: inline-block; */
  /* border: 1px solid red; */
  margin: auto;
}
.member_checkbox {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #000000;
  font-size: 13px;
  /* border: 1px solid forestgreen; */
}
.details {
  width: 100%;
  height: 55px;
  line-height: 55px;
  /* border: 1px solid #0084FF; */
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #000000;
  font-size: 12px;
  margin-top: 10px;
}
.details_content {
  width: 25%;
  vertical-align: top;
  /* border: 1px solid firebrick; */
  display: inline-block;
}
.details_contents {
  width: 15%;
  vertical-align: top;
  /* border: 1px solid firebrick; */
  display: inline-block;
}
.indexs {
  width: 5%;
  text-align: center;
  display: inline-block;
  /* border: 1px solid darkgreen; */
}
.spl {
  width: 100px;
  display: inline-block;
  position: relative;
  top: -5px;
  right: -3px;
  /* border: 1px solid red; */
  /* margin-right: 50px; */
}
.Onekey {
  display: inline-block;
  position: relative;
  top: -5px;
  cursor: pointer;
  color: #0084ff;
}
</style>
